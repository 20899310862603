import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';

import {TextSelectorPt} from './TextSelector';

function TermsAndPrivacyPt({type}) {
	const { t } = useTranslation();

	useEffect(() => {
		document.title = t(`${type}.pageTitle`)
	})
	return (
		<div className='c-terms-and-privacy'>
			<h1 className='terms-and-privacy__title'>{t(`${type}.title`)}</h1>
			<TextSelectorPt type={type}/>
		</div>
	)
}

export default TermsAndPrivacyPt