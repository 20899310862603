import { useTranslation } from "react-i18next";
import { useEffect } from "react";

import { TextSelectorEn } from "./TextSelector";

function TermsAndPrivacyEn({ type }) {
  const { t } = useTranslation();

  useEffect(() => {
    document.title = t(`${type}.pageTitle`);
  });
  return (
    <div className="c-terms-and-privacy">
      <h1 className="terms-and-privacy__title">{t(`${type}.title`)}</h1>
      <TextSelectorEn type={type} />
    </div>
  );
}

export default TermsAndPrivacyEn;
